import React from 'react';

import Section from '../utils/Section';

export default function Footer() {
  return (
    <Section>
      <h5 className="text-xl md:text-2xl lg:text-3xl mb-32">
        With love, from Emma & Alex x
      </h5>
    </Section>
  );
}
