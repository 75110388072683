import React from 'react';

import './MenuIcon.css';

interface Props {
  open: boolean;
  onClick: () => void;
}

export default function MenuIcon({ open, onClick }: Props) {
  return (
    <div className="menu-icon-container m-5 scale-[0.7]">
      <button
        className={`menu-icon ${open ? 'open' : ''}`}
        type="button"
        onClick={onClick}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  );
}
