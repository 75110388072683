import React from 'react';

import Heading from '../../utils/Heading';
import Section from '../../utils/Section';

import './Timeline.css';

interface TimelineEvent {
  time: string;
  event: string;
  event_time?: Date;
}

// on the actual day we want to change the display of the timeline to show actions
// that are completed and those that are still to come
// we can do this by comparing the current date to the wedding date
// and the current time to the event time
const wedding_date = new Date('2024-09-14');
const today = new Date();
const events: TimelineEvent[] = [
  {
    time: '1:00 pm',
    event: 'Guests arrive',
    event_time: new Date(wedding_date.setHours(13, 0, 0, 0)),
  },
  {
    time: '2:00 pm',
    event: 'Ceremony starts',
    event_time: new Date(wedding_date.setHours(14, 0, 0, 0)),
  },
  {
    time: '2:30 pm',
    event: 'Confetti & group photo',
    event_time: new Date(wedding_date.setHours(14, 30, 0, 0)),
  },
  {
    time: '2:45 pm',
    event: 'Cocktails & canapés',
    event_time: new Date(wedding_date.setHours(14, 45, 0, 0)),
  },
  {
    time: '4:00 pm',
    event: 'Speeches',
    event_time: new Date(wedding_date.setHours(16, 0, 0, 0)),
  },
  {
    time: '4:45 pm',
    event: 'Dinner served',
    event_time: new Date(wedding_date.setHours(16, 45, 0, 0)),
  },
  {
    time: '6:45 pm',
    event: 'Tea & coffee served',
    event_time: new Date(wedding_date.setHours(18, 45, 0, 0)),
  },
  {
    time: '8:00 pm',
    event: 'Cake cut & first dance',
    event_time: new Date(wedding_date.setHours(20, 0, 0, 0)),
  },
  {
    time: '9:00 pm',
    event: 'Evening food served',
    event_time: new Date(wedding_date.setHours(21, 0, 0, 0)),
  },
  {
    time: '11:45 pm',
    event: 'Bar closes',
    event_time: new Date(wedding_date.setHours(23, 45, 0, 0)),
  },
  {
    time: 'Midnight',
    event: 'Finish & carriages',
    event_time: new Date(wedding_date.setHours(23, 59, 0, 0)),
  },
];

export default function Timeline() {
  return (
    <Section id="timeline">
      <Heading text="Timeline" />
      <div>
        {events.map((event: TimelineEvent, index: number) => (
          <TimelineItem
            key={event.time}
            event={event}
            isLastItem={index === events.length - 1}
          />
        ))}
      </div>
    </Section>
  );
}

function TimelineItem({
  isLastItem,
  event,
}: {
  isLastItem: boolean;
  event: TimelineEvent;
}) {
  return (
    <li className="relative flex flex-row align-top min-h-[100px] ml-8 md:ml-12 lg:ml-20">
      <div className="flex flex-col text-right w-[75px] md:w-[100px] lg:w-[150px]">
        <h5 className="text-xl lg:text-2xl font-bold">{event.time}</h5>
      </div>
      <div className="timeline-item">
        <span
          className={
            'timeline-item__icon ' +
            // if the event time is in the done, we want to show the icon as green
            // if the event time is in the future, we want to show the icon as grey
            (event.event_time && event.event_time < today
              ? ' bg-[#dbc16f]'
              : ' bg-stone-400')
          }
        />
        {!isLastItem && (
          <span
            className={
              'timeline-item__line ' +
              (event.event_time && event.event_time < today
                ? ' bg-[#dbc16f]'
                : ' bg-stone-400')
            }
          />
        )}
      </div>
      <div className="flex flex-col text-left w-[150px] md:w-[400px]">
        <h5 className="text-xl lg:text-2xl">{event.event}</h5>
      </div>
    </li>
  );
}
