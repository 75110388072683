import React from 'react';

import Heading from '../../utils/Heading';
import Section from '../../utils/Section';
import AddToCal from '../../utils/AddToCal';

import './Details.css';

export default function Details() {
  return (
    <Section id="details">
      <Heading text="Details" />
      <h5 className="text-xl md:text-2xl lg:text-3xl p-8 hover:bg-stone-100 rounded-xl">
        We'll be tying the knot
      </h5>

      <div className="text-separator mb-8">
        <h6 className="text-separator__text text-l md:text-xl lg:text-2xl">
          at
        </h6>
      </div>

      <h5 className="text-2xl md:text-3xl lg:text-4xl p-8 hover:bg-stone-100 rounded-xl">
        Coombe Lodge, North Somerset
      </h5>

      <div className="text-separator mb-8">
        <h6 className="text-separator__text text-l md:text-xl lg:text-2xl">
          on
        </h6>
      </div>

      <h5 className="text-2xl md:text-3xl lg:text-4xl p-8 hover:bg-stone-100 rounded-xl">
        Thursday the 12th of September 2024
      </h5>

      <div className="text-separator mb-8">
        <h6 className="text-separator__text text-l md:text-xl lg:text-2xl">
          at
        </h6>
      </div>

      <h5 className="text-2xl md:text-3xl lg:text-4xl p-8 hover:bg-stone-100 rounded-xl">
        2pm in the afternoon
      </h5>

      <div className="mt-32">
        <AddToCal />
      </div>
    </Section>
  );
}
