import React from 'react';

interface Props {
  alwaysWobble?: boolean;
}

export default function Logo(props: Props) {
  return (
    <div
      className={`p-5 animate-wobble ${
        props.alwaysWobble ? ' ' : 'hover:animate-wobbleinfinite'
      }`}
    >
      <img src="/images/logo.png" alt="E&A" className="w-10" />
    </div>
  );
}
