import React from 'react';

import Heading from '../utils/Heading';
import Section from '../utils/Section';
import TicketWrapper from './TicketWrapper';
import ExternalLink, { DEFAULT_LINK_CLASS } from '../utils/ExternalLink';

export const RSVP_FORM_URL = 'https://forms.gle/Ti5s8LeWkS9aGLLR8';

export default function RSVPOpen() {
  const emailBody = `What is your name?

What is your email address?

What is your phone number?

Are you a +1?

If so, what is the name of the invited guest?

Do you have any accommodation needs?

Do you have any dietary requirements?

Any song requests?

`;

  const email = 'emmaandalexgoff@gmail.com';
  const subject = 'Goff Wedding RSVP';

  const prepareMailToEmail = () => {
    const mailToEmail = `mailto:${email}?subject=${subject}&body=${encodeURI(
      emailBody,
    )}`;
    return mailToEmail;
  };

  // const copyToClipboard = (text: string) => {
  //   navigator.clipboard.writeText(text);
  // };

  return (
    <Section id="rsvp">
      <Heading text="RSVP" />

      <h5 className="text-xl md:text-2xl lg:text-3xl mb-16 ">
        Invited guests, please kindly RSVP
        <br />
        by clicking the ticket below before the{' '}
        <span className="font-bold">1st of June 2024</span>
      </h5>

      <ExternalLink url={RSVP_FORM_URL}>
        <TicketWrapper>
          <div className=" relative w-[230px] sm:w-[600px] h-[100px] sm:h-[300px] px-[60px] py-[4rem] w-full h-full font-mono">
            <div className="absolute text-amber-100 font-bold text-4xl sm:text-8xl -top-5 sm:-top-8 left-0 line-clamp-1 w-full whitespace-nowrap overflow-hidden">
              RSVP RSVP
            </div>
            <div className="absolute text-amber-100 font-bold text-4xl sm:text-8xl -bottom-5 sm:-bottom-8 left-0 line-clamp-1 w-full whitespace-nowrap overflow-hidden">
              RSVP RSVP
            </div>
            <div className="absolute text-stone-600 font-extrabold sm:p-10 sm:pt-32 top-0 left-0 sm:left-auto sm:right-0  sm:text-4xl text-right ">
              12 SEPT 2024
            </div>
            <div className="absolute text-stone-600 pt-12   sm:p-10 sm:pt-32 flex items-center flex-col top-0 left-0 text-left text-sm sm:text-m ">
              <div className="text-lg sm:text-2xl pl-6 w-full">Admission</div>
              <div className="text-sm sm:text-lg w-full ">
                Coombe Lodge, Blagdon
              </div>
            </div>
          </div>
        </TicketWrapper>
      </ExternalLink>

      <div className="inline-block mt-24">
        <h5 className="text-l md:text-xl lg:text-2xl">
          If you have any issues with filling out the above form, <br />
          please don't hesitate to phone Emma or Alex, or{' '}
          <button
            onClick={() => {
              window.open(prepareMailToEmail(), '_blank');
            }}
          >
            <span className={DEFAULT_LINK_CLASS}>send us an email</span>
          </button>
        </h5>
      </div>
    </Section>
  );
}
