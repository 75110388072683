import React from 'react';

import Heading from '../utils/Heading';
import Section from '../utils/Section';

import { DEFAULT_LINK_CLASS } from '../utils/ExternalLink';

export default function RSVPClosed() {
  const email = 'emmaandalexgoff@gmail.com';
  const subject = 'Goff Wedding RSVP';

  const prepareMailToEmail = () => {
    const mailToEmail = `mailto:${email}?subject=${subject}}`;
    return mailToEmail;
  };

  return (
    <Section id="rsvp">
      <Heading text="RSVP" />

      <h5 className="text-l md:text-xl lg:text-2xl mb-16">
        Thank you so much for your RSVPs!
        <br />
        <br />
        We're looking forward to seeing you all very soon.
      </h5>

      <h5 className="text-m md:text-l lg:text-xl">
        If you're not sure if you've RSVP'd, or need to change your response,
        please{' '}
        <button
          onClick={() => {
            window.open(prepareMailToEmail(), '_blank');
          }}
        >
          <span className={DEFAULT_LINK_CLASS}>send us an email</span>.
        </button>
      </h5>
    </Section>
  );
}
