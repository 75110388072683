import React from 'react';

import RSVPOpen from './RSVPOpen';
import RSVPClosed from './RSVPClosed';

export default function RSVP() {
  const RSVPisOpen = new Date().getTime() < 1717286384000; // Open before 2nd June 2024

  return RSVPisOpen ? <RSVPOpen /> : <RSVPClosed />;
}
