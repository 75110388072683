import React from 'react';

import Section from '../utils/Section';

export default function Intro() {
  return (
    <Section id="intro">
      <h4 className="text-xl md:text-2xl lg:text-3xl">
        Alex and Emma met at Cardiff University back in 2014, and have called 25
        places home together since then.
        <br />
        <br />
        From Korean dog cafes, to getting stuck in Paris; from starting a
        nomadic lifestyle to a cliff top proposal; we’ve already been so lucky
        to experience such wild and wonderful adventures together - we’re so
        excited for you to join us as we embark on the biggest adventure of all.
      </h4>
    </Section>
  );
}
