import React from 'react';
import MenuIcon from './MenuIcon/MenuIcon';

const menuItems = [
  { id: 'details', label: 'Details', link: '#details' },
  { id: 'rsvp', label: 'RSVP', link: '/rsvp' },
  { id: 'timeline', label: 'Timeline', link: '#timeline' },
  { id: 'faqs', label: 'FAQs', link: '#faqs' },
];

export default function Menu() {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    const newOpenValue = !open;
    setOpen(newOpenValue);
  };

  return (
    <>
      <div
        className={`h-[100vh] w-[100vw] absolute top-0 z-9  ${
          open ? 'flex' : 'hidden'
        }`}
      >
        <button className={`h-[100vh] w-[100vw]`} onClick={toggleOpen} />
        <div
          className={`top-[94px] absolute bg-stone-50 shadow-2xl w-full flex flex-col z-10`}
        >
          <div className="relative w-full h-full flex flex-col px-8 py-3">
            {menuItems.map(({ id, label, link }, index) => (
              <a
                className={`px-4 py-6 ${
                  index !== 0 &&
                  'border border-solid border-x-0 border-b-0 border-stone-200 border-b-1'
                }`}
                key={id}
                href={link}
                onClick={toggleOpen}
              >
                <h5 className="text-2xl lg:text-4xl">{label}</h5>
              </a>
            ))}
          </div>
        </div>
      </div>

      <MenuIcon open={open} onClick={toggleOpen} />
    </>
  );
}
