import React from 'react';

interface Props {
  children: React.ReactNode;
  onClick?: () => void | Promise<void>;
  isDisabled?: boolean;
}

export const DEFAULT_BUTTON_CLASS =
  'hover:bg-stone-200 bg-white flex flex-row items-center ring ring-1 ring-solid ring-stone-200 px-4 py-2 rounded';

export default function Button(props: Props) {
  const [loading, setLoading] = React.useState(false);

  const whenClicked = async () => {
    setLoading(true);
    setTimeout(async () => {
      if (props.onClick) {
        await props.onClick();
      }
      setLoading(false);
    }, 1000);
  };

  return (
    <button
      disabled={props.isDisabled}
      onClick={async () => await whenClicked()}
      className={
        DEFAULT_BUTTON_CLASS +
        ` ` +
        (props.isDisabled ? 'opacity-30 cursor-not-allowed' : 'cursor-pointer')
      }
    >
      {loading ? 'Loading...' : props.children}
    </button>
  );
}
