import React from 'react';

export default function Section({
  children,
  noPadding,
  id,
}: {
  children: React.ReactNode;
  noPadding?: boolean;
  id?: string;
}) {
  return (
    <section
      id={id}
      className={`flex flex-col items-center justify-center text-center h-full ${
        noPadding ? '' : 'pb-12 md:pb-24 lg:pb-32 pt-24 px-6 md:px-24 lg:px-48'
      }`}
    >
      {children}
    </section>
  );
}
