import React from 'react';

interface Props {
  title: string;
  answer: () => React.ReactNode;
  id: string;
}

export default function FAQ(props: Props) {
  const { title, answer, id } = props;

  const shouldShow = window.location.hash.slice(1) === id;

  const [show, setShow] = React.useState(shouldShow);

  return (
    <div
      id={`faqs-${id}`}
      className="w-full md:w-4/5 xl:w-3/5 bg-white border border-solid border-1 border-stone-200 rounded-xl my-2"
    >
      <button
        className={
          'py-3 px-4 flex justify-between items-center w-full ' +
          (show
            ? 'border border-solid border-x-0 border-t-0 border-stone-200 border-b-1'
            : '')
        }
        onClick={() => {
          if (!show) {
            window.location.replace(`#${id}`);
          }
          setShow(!show);
        }}
      >
        <h4 className="text-left font-bold text-xl md:text-2xl lg:text-3xl">
          {title}
        </h4>{' '}
        <span className="font-bold text-xl md:text-2xl lg:text-3xl">
          {show ? '-' : '+'}
        </span>
      </button>

      {show ? <div className="text-left p-5 md:p-8">{answer()}</div> : null}
    </div>
  );
}
