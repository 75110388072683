import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function TicketWrapper(props: Props) {
  const cornersCommonClass = ' absolute rounded-full ';
  const externalCornersCommonClass =
    ' bg-white ring ring-[10px] sm:ring-[20px] ring-amber-100 ring-solid w-8 h-8 sm:w-12 sm:h-12 ' +
    cornersCommonClass;

  return (
    <div
      className=" hover:animation-none  sm:m-3 shadow-inner p-2 sm:p-5 bg-amber-100  bg-animate-gradient relative overflow-hidden rounded-lg  "
      style={{
        animationDuration: '4s',
        animationName: 'cube-rotate',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
        animationTimingFunction: 'ease-in-out',
      }}
    >
      <div
        className="w-full flex flex-row"
        style={{
          background: `linear-gradient(24deg, rgba(50 0 255/10%), #e4c41b),
          url("data:image/svg+xml,%3Csvg viewBox='0 0 400 310' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.55' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,

          color: 'transparent',
          animationDuration: '4s',
          animationName: 'shimmer',
          animationIterationCount: 'infinite',
          animationDirection: 'alternate',
        }}
      >
        <div className="sm:p-1 overflow-hidden">{props.children}</div>
        <div className="w-1 border border-[3px] border-white border-dashed "></div>
        <div className="w-[6em] sm:w-[16em] relative overflow-hidden bg-stone-200">
          {/* // have the image fill the full div but align the edge of the image to the right */}
          <img
            className="absolute w-full h-full object-cover "
            src="/images/hero.jpg"
            alt="Emma & Alex in Westonbirt"
          />
        </div>

        {/* content */}
      </div>
      <>
        {/* mid cuts T+B */}
        <div
          className={
            'right-0 top-0 -mt-6  mr-20 sm:mr-[16.5em] ' +
            externalCornersCommonClass
          }
        ></div>
        <div
          className={
            'right-0 bottom-0 -mb-6  mr-20 sm:mr-[16.5em] ' +
            externalCornersCommonClass
          }
        ></div>
        {/* mid cuts L+R */}
        <div
          className={
            'right-0 top-0 -mr-4  mt-[50px] sm:mt-[150px]  ' +
            externalCornersCommonClass
          }
        ></div>
        <div
          className={
            'left-0 top-0 -ml-4 mt-[50px] sm:mt-[150px] ' +
            externalCornersCommonClass
          }
        ></div>
      </>

      <div
        className="overlay"
        style={{
          background: 'antiquewhite',
          mixBlendMode: 'multiply',
        }}
      ></div>
    </div>
  );
}
