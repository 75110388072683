import React from 'react';

import Hero from '../../components/Landing/Hero';
import LandingHeader from '../../components/Landing/LandingHeader';
import Intro from '../../components/Landing/Intro';
import Details from '../../components/Landing/Details/Details';
import RSVP from '../../components/Landing/RSVP';
import Timeline from '../../components/Landing/Timeline/Timeline';
import FAQs from '../../components/Landing/FAQs';
import Footer from '../../components/Landing/Footer';
import Navbar from '../../components/Landing/Navbar';
import Arrow from '../../components/utils/Arrow';
import Divider from '../../components/utils/Divider';
import { isMobile } from '../../utils';

import './Landing.css';

export default function LandingPage() {
  const headerHeight = '94px';
  const heroHeight = `calc(${isMobile() ? '50vh' : '60vh'} - ${headerHeight})`;
  const landingHeaderHeight = isMobile() ? `30vh` : `35vh`;

  React.useEffect(() => {
    const linkedFaq = document.getElementById(
      `faqs-${window.location.hash.slice(1)}`,
    );

    if (linkedFaq && linkedFaq.offsetParent !== null) {
      scrollTo({
        behavior: 'smooth',
        top: linkedFaq.getBoundingClientRect().top - 24,
      });
    }
  }, []);

  return (
    <div className="h-full m-auto bg-white text-stone-900">
      <div className="flex items-center flex-col">
        <div className="max-w-[1700px] w-full">
          <Navbar height={headerHeight} />
          <div className="h-[100vh] relative">
            <div style={{ height: heroHeight }} className={`px-4 md:px-6 `}>
              <Hero height={heroHeight} />
            </div>
            <div style={{ height: landingHeaderHeight }}>
              <LandingHeader />
            </div>
            <Arrow />
          </div>

          <Intro />
          <Divider />
          <Details />
          <Divider />
          <Timeline />
          <Divider />
          <FAQs />
          <Divider />
          <RSVP />
          <Divider />
          <Footer />
        </div>
      </div>
    </div>
  );
}
