import React, { useEffect } from 'react';
import Logo from './Logo';

interface Props {
  url: string;
}

const Redirect = (props: Props) => {
  const { url } = props;

  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return (
    <div className="h-full m-auto bg-stone-50 text-stone-900">
      <div className="h-[100vh] flex items-center justify-center">
        <Logo alwaysWobble />
      </div>
    </div>
  );
};

export default Redirect;
