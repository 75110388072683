import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { routes } from './pages/routes';
import LandingPage from './pages/Landing/Landing';
import { RSVP_FORM_URL } from './components/Landing/RSVPOpen';
import Redirect from './components/utils/Redirect';

export default function App() {
  const notion_schedule_url =
    'https://emma-and-alex-goff.notion.site/Schedule-cac49728c36e46dfb03ad70b963e1eee?pvs=4';
  return (
    <div className="min-h-[100vh] min-w-[100vw]">
      <Routes>
        <Route path={routes.landing} element={<LandingPage />} />
        <Route path={routes.rsvp} element={<Redirect url={RSVP_FORM_URL} />} />
        <Route path="*" element={<Redirect url={routes.landing} />} />
        <Route
          path="/schedule"
          element={<Redirect url={notion_schedule_url} />}
        />
      </Routes>
    </div>
  );
}
