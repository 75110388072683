import React from 'react';

import FAQ from './FAQ';
import Heading from '../utils/Heading';
import Section from '../utils/Section';
import ExternalLink from '../utils/ExternalLink';

export default function FAQs() {
  const FAQs = [
    {
      title: 'When do I need to RSVP by?',
      key: 'when_to_rsvp',
      answer: () => {
        return (
          <div>
            <p>
              Please RSVP{' '}
              <ExternalLink url={'https://forms.gle/Ti5s8LeWkS9aGLLR8'}>
                via this form
              </ExternalLink>{' '}
              before the 1st of June, to give us plenty of time to accommodate
              you. Please don't hesitate to get in touch with us if you have any
              questions.
            </p>
          </div>
        );
      },
    },
    {
      title: 'When should we arrive?',
      key: 'arrival',
      answer: () => {
        return (
          <div>
            <p>
              Our ceremony will be starting at 2pm, so we ask you to arrive
              between 1:00pm and 1:30pm, so we can get you seated in plenty of
              time!
            </p>
          </div>
        );
      },
    },
    {
      title: 'What should I wear?',
      key: 'dress_code',
      answer: () => {
        return "Standard wedding dress code please! Let's see your best suits and dresses!";
      },
    },
    {
      title: 'How do we get there?',
      key: 'getting_there',
      answer: () => {
        return (
          <div>
            <p>
              Coombe Lodge is located near Blagdon village, only 10 miles south
              west of Bristol, two miles off the A38, and within four miles of
              Bristol International Airport. The postcode is <b>BS40 7RE</b>.
              There is ample parking available at the venue.{' '}
              <ExternalLink url="https://www.google.com/maps?saddr=My+Location&daddr=Coombe+Lodge,+Blagdon+BS40+7RE">
                Click here to get directions.
              </ExternalLink>
            </p>
          </div>
        );
      },
    },
    {
      title: 'Where can we stay overnight?',
      key: 'accommodation',
      answer: () => {
        return (
          <div>
            <p>
              We appreciate many of you are travelling many miles to be with us.
              Unless we’ve reached out to you explicitly, I’m afraid we won’t be
              able to offer accommodation on-site. Here’s a list of recommended
              accommodation in the area.
            </p>

            <br />
            <h3 className="text-2xl">Hotels</h3>

            <h5 className="my-2 font-bold">
              <ExternalLink url="https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=BRSHX&arrivalDate=2024-09-12&departureDate=2024-09-13&room1NumAdults=2">
                Hampton by Hilton
              </ExternalLink>
            </h5>

            <p>
              Casual rooms with vibrant accents and complimentary buffet
              breakfast. (16 minute drive)
            </p>

            <h5 className="my-2 font-bold">
              <ExternalLink url="https://www.premierinn.com/gb/en/hotels/england/bristol/bristol/bristol-sidcot-a38.html?INNID=BRISID&ARRdd=22&ARRmm=10&ARRyyyy=2023&NIGHTS=1&ROOMS=1&ADULT1=2&CHILD1=0&COT1=0&INTTYP1=DB&SID=4&ISH=true&BRAND=PI&CID=GHF3_GB_GoogleSearch_desktop_default+BRISID)">
                Premier Inn Sidcot
              </ExternalLink>
            </h5>

            <p>
              Modern budget hotel is set in a Victorian country house with its
              own grounds, free parking with an adjoining pub/restaurant. (13
              minute drive)
            </p>

            <h5 className="my-2 font-bold">
              <ExternalLink url="http://www.redhillhousehotel.co.uk/">
                Redhill House
              </ExternalLink>
            </h5>

            <p>
              Modern boutique hotel with elegant rooms, with an indoor pool,
              jacuzzi and free parking. (8 minute drive)
            </p>

            <h5 className="my-2 font-bold">
              <ExternalLink url="https://www.hilton.com/en/hotels/brsdtdi-doubletree-bristol-south-cadbury-house/?SEO_id=GMB-EMEA-DI-BRSDTDI">
                DoubleTree by Hilton - Cadbury House
              </ExternalLink>
            </h5>

            <p>
              Hotel and spa dating from 1790 built on six acres of landscaped
              gardens at the bottom of Cadbury Hill Nature Reserve. Electric car
              chargers available. (18 minute drive)
            </p>

            <br />

            <h3 className="text-2xl">B&Bs</h3>

            <h5 className="my-2 font-bold">
              <ExternalLink url="https://seymourarms.co.uk/">
                The Seymour Arms
              </ExternalLink>
            </h5>

            <p>
              A casual pub, set in a half-timbered building, with warm rooms
              less than a mile from Blagdon Lake. Rooms feature hardwood floors
              and en suite bathrooms. (2 minute drive)
            </p>

            <h5 className="my-2 font-bold">
              <ExternalLink url="https://unwindatburringtonfarm.co.uk/">
                Burrington Farm B&B
              </ExternalLink>
            </h5>

            <p>
              A traditional B&B in both the main house and newly converted
              barns. (5 minute drive)
            </p>

            <h5 className="my-2 font-bold">
              <ExternalLink url="https://schoolfarmbedandbreakfast.co.uk/">
                School Farm B&B
              </ExternalLink>
            </h5>

            <p>
              Three ensuite rooms in a newly refurbished farmhouse, one with
              easy accessibility options. (3 minute drive)
            </p>

            <h5 className="my-2 font-bold">
              <ExternalLink url="https://langfordinnsomerset.co.uk/">
                The Langford Inn
              </ExternalLink>
            </h5>

            <p>
              Traditional 17th-century inn offering cosy oak-beamed rooms, plus
              a pub restaurant. (8 minute drive)
            </p>

            <h5 className="my-2 font-bold">
              <ExternalLink url="https://thelitton.co.uk/">
                The Litton
              </ExternalLink>
            </h5>

            <p>
              Award-winning boutique hotel and destination pub. (16 minute
              drive)
            </p>

            <br />

            <p>
              There are many more B&Bs located around Blagdon, and especially
              nearer Bristol airport. If you need any help finding
              accommodation, please get in touch with us.
            </p>
          </div>
        );
      },
    },
    {
      title: 'When is check in and check out?',
      key: 'check_in',
      answer: () => {
        return (
          <div>
            <p>
              If you are staying at Coombe Lodge, you'll be able to check in at
              the latest at 2pm, but you'll be able to leave your luggage with
              reception after 11:30am. The following day, check out is at 9:15am
              and breakfast will be served at 9:30am. We still have exclusive
              use of the rest of the venue until 10:30am. Basic amenities are
              provided in the room, including Wi-Fi, shower gel, shampoo and
              body lotion, a hairdryer, and an iron.
            </p>
            <br />
            <p>
              If you are staying in the{' '}
              <ExternalLink url="https://www.thegardenhouserooms.co.uk/">
                Garden House
              </ExternalLink>
              , you'll be able to check in from 12pm. The following day,
              breakfast will be served between 9am and 10am. Check out is at
              10:30am.
            </p>
          </div>
        );
      },
    },
    {
      title: 'Can I bring my children, or my dogs?',
      key: 'dogs_and_children',
      answer: () => {
        return 'We kindly request that our celebration be reserved for adult guests. We appreciate your understanding. Unfortunately, our venue does not allow dogs.';
      },
    },
    {
      title: 'Do you have a gift registry?',
      key: 'gift_registry',
      answer: () => {
        return (
          <div>
            <p>
              We don't have a registry - we are lucky enough to have everything
              we need for our home. Please know that your presence on our big
              day is generous enough, but if you feel so inclined, we’d be truly
              grateful for a contribution towards our honeymoon fund.{' '}
              <ExternalLink url="https://monzo.me/alexandergoff">
                Please follow this link to do so.
              </ExternalLink>
            </p>
          </div>
        );
      },
    },
    {
      title: 'Can we take photos?',
      key: 'photos',
      answer: () => {
        return (
          <div>
            <p>
              We kindly request that you refrain from taking photos during our
              ceremony.{' '}
              <ExternalLink url="https://www.instagram.com/p/CwvFa4qOmR3/">
                Here's a preview of what we might ask our photographer to do
                otherwise!
              </ExternalLink>{' '}
              Your presence means the world to us, and we've hired professional
              photographers to capture all the beautiful moments. Don’t worry,
              we’ll be sure to share the photographs with you afterwards!
            </p>
            <br />
            <p>
              After the ceremony, feel free to take as many photos as you like!
            </p>
          </div>
        );
      },
    },
    {
      title: 'Can we throw confetti?',
      key: 'confetti',
      answer: () => {
        return (
          <div>
            <p>
              Right after the ceremony, we'd love for you to shower us with
              confetti! We will be providing confetti, but if you'd like to
              bring your own, Coombe Lodge have requested only dried natural
              petal confetti to be used.
            </p>
          </div>
        );
      },
    },
    {
      title: 'How do I get back to my accommodation after?',
      key: 'getting_home_taxis',
      answer: () => {
        return (
          <div>
            <p>
              We’ve collated a list of taxi services nearby with the help of our
              venue. The venue has advised that taxis must be booked in advance.
            </p>
            <br />
            <p>
              <h5 className="font-bold">Aldwick Minibus - Howard Grant</h5>
              <span>07954 168 186</span>
            </p>
            <p>
              <h5 className="font-bold">V Cars (Bristol)</h5>
              <span>0117 925 2626</span>
            </p>
            <p>
              <h5 className="font-bold">Arrow Cars (Bristol Airport)</h5>
              <span>0113 258 5888</span>
            </p>
            <p>
              <h5 className="font-bold">Trusted Independent Transfers</h5>
              <span>0800 002 9933</span>
            </p>
            <p>
              <h5 className="font-bold">BSG Minibuses</h5>
              <span>07429 622 665</span>
            </p>
            <p>
              <h5 className="font-bold">South West Minibuses</h5>
              <span>0117 965 0000</span>
            </p>
          </div>
        );
      },
    },
    {
      title: 'Is the venue accessible?',
      key: 'accessibility',
      answer: () => {
        return 'There are a couple of steps as you come into the venue from all entrances, but the venue has ramps which can easily move throughout. In the Garden House, the ground floor rooms are wheelchair accessible.';
      },
    },
    {
      title: 'Can I bring a plus one?',
      key: 'plus_ones',
      answer: () => {
        return 'We kindly request that, unless otherwise stated on your invitation, only those individuals who have been specifically named on the invitation join us. We hope you understand.';
      },
    },
  ];

  return (
    <Section id="faqs">
      <Heading text="FAQs" />
      <div className="flex w-full justify-center items-center flex-col m-auto">
        {FAQs.map(({ title, answer, key }) => {
          return <FAQ title={title} answer={answer} key={key} id={key} />;
        })}
      </div>
    </Section>
  );
}
