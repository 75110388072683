import React from 'react';

export default function Arrow() {
  const [show, setShow] = React.useState(false);
  const [wobble, setWobble] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);

    setTimeout(() => {
      setWobble(true);
    }, 5000);
  }, []);

  React.useEffect(() => {
    document.addEventListener('scroll', () => {
      setWobble(false);
    });

    return () => {
      document.removeEventListener('scroll', () => {
        setWobble(false);
      });
    };
  }, []);

  const scrollDown = () =>
    scrollBy({
      behavior: 'smooth',
      top: document.getElementById('intro')?.getBoundingClientRect().top,
    });

  const animationClass = `opacity-0 transition-opacity ease-in-out ${
    show ? ' opacity-100' : ''
  } ${wobble ? ' animate-bounce' : ''}`;

  return (
    <div className="absolute w-full">
      <div className="flex justify-center text-stone-500">
        <button onClick={scrollDown}>
          <div className={animationClass}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}
