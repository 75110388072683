import React from 'react';

import { DEFAULT_BUTTON_CLASS } from './Button';

export const DEFAULT_LINK_CLASS = 'underline hover:text-gray-500';

export default function ExternalLink({
  url,
  children,
  style,
}: {
  url: string;
  children: React.ReactNode;
  style?: 'link' | 'button';
}) {
  return (
    <a
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      className={style === 'button' ? DEFAULT_BUTTON_CLASS : DEFAULT_LINK_CLASS}
    >
      {children}
    </a>
  );
}
