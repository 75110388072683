import React from 'react';

import Button from './Button';
import ExternalLink from './ExternalLink';

const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
BEGIN:VTIMEZONE
TZID:Europe/London
LAST-MODIFIED:20230407T050750Z
TZURL:https://www.tzurl.org/zoneinfo-outlook/Europe/London
X-LIC-LOCATION:Europe/London
BEGIN:DAYLIGHT
TZNAME:BST
TZOFFSETFROM:+0000
TZOFFSETTO:+0100
DTSTART:19700329T010000
RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU
END:DAYLIGHT
BEGIN:STANDARD
TZNAME:GMT
TZOFFSETFROM:+0100
TZOFFSETTO:+0000
DTSTART:19701025T020000
RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
DTSTAMP:20230929T215935Z
UID:1696024761488-91728@ical.marudot.com
DTSTART;TZID=Europe/London:20240912T130000
DTEND;TZID=Europe/London:20240912T235900
SUMMARY:Emma & Alex's Wedding
DESCRIPTION:Our ceremony starts at 2pm. Can't wait to see you there! x
URL:https://emmaandalexgoff.co.uk
LOCATION:Coombe Lodge\\, Blagdon\\, Bristol\\, BS40 7RE\\, England
X-APPLE-STRUCTURED-LOCATION;VALUE=URI;X-APPLE-MAPKIT-HANDLE=CAESiAIIrk0Q/euHj7D/yp7/ARoSCdQP6iKFqklAEf578NqlzQXAIl4KDlVuaXRlZCBLaW5nZG9tEgJHQhoHRW5nbGFuZDIHQnJpc3RvbDoIQlM0MCA3UkVCB0JsYWdkb26KARhCbGFnZG9uICYgQ2h1cmNoaWxsIFdhcmSKAQdCbGFnZG9uKgxDb29tYmUgTG9kZ2UyB0JsYWdkb24yB0JyaXN0b2wyCEJTNDAgN1JFMgdFbmdsYW5kOC9QAVpNCiUI/euHj7D/yp7/ARISCdQP6iKFqklAEf578NqlzQXAGK5NkAMBoh8jCP3rh4+w/8qe/wEaFgoMQ29vbWJlIExvZGdlEAAqAmVuQAA=;X-APPLE-RADIUS=141.1751098632812;X-APPLE-REFERENCEFRAME=0;X-TITLE="Coombe Lodge, Blagdon, Bristol, BS40 7RE, England":geo:51.332188,-2.725414
END:VEVENT
END:VCALENDAR`;

export default function AddToCal() {
  function downloadICal() {
    console.log(icsContent);

    // Create a Blob from the iCalendar content
    const blob = new Blob([icsContent], {
      type: 'text/calendar;charset=utf-8',
    });

    // Create a link element to use for the download
    const downloadLink = document.createElement('a');
    downloadLink.download = 'emma_and_alex_wedding.ics';
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.style.display = 'none';

    // Add the link to the DOM and click it to trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Remove the link from the DOM
    document.body.removeChild(downloadLink);
  }

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center">
      <div className="mb-3 lg:mr-2">
        <ExternalLink
          url="https://www.google.com/calendar/render?action=TEMPLATE&text=Emma+%26+Alex%27s+wedding&details=Our+ceremony+starts+at+2pm%2E%0ACan%27t+wait+to+see+you+there%21+x&location=Coombe+Lodge%2C+Blagdon%2C+Bristol+BS40+7RE%2C+UK&dates=20240912T130000%2F20240912T235900"
          style="button"
        >
          <img
            src="https://www.gstatic.com/images/branding/product/2x/calendar_48dp.png"
            alt="Gmail Logo"
            className="w-6 h-6 mr-2"
          />
          Add to your Google calendar
        </ExternalLink>
      </div>

      <div className="mb-3 rounded">
        <Button onClick={downloadICal}> Add to your Apple calendar</Button>
      </div>
    </div>
  );
}
