import React from 'react';

export default function Heading({ text }: { text: string }) {
  return (
    <div className="text-center py-2 pb-24 md:pb-32">
      <h1 className="text-4xl md:text-5xl lg:text-6xl">
        <a href={`#${text.toLowerCase().replaceAll(' ', '_')}`}>{text}</a>
      </h1>
    </div>
  );
}
